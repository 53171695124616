// src/App/components/SanitizedContent.js
import React from 'react';
import DOMPurify from 'dompurify';

export const SanitizedContent = ({ dirtyHTML }) => {
  const cleanHTML = DOMPurify.sanitize(dirtyHTML);

  return (
    <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
  );
};

export default SanitizedContent;
