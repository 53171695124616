import React from 'react';
import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-parallax';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../lang/i18n'; // Initialize i18n
import Button from '../components/buttons'; // Import the new Button component
import 'aos/dist/aos.css';
import { initializeAOS } from '../animation/aos-init';
import home4 from '../assets/img/Home/Home4.png';
import home3 from '../assets/img/Home/home03.webp'
import { Link as ScrollLink } from 'react-scroll';

export const Home = () => {
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    initializeAOS();
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <Parallax 
        id='home' 
        loading="lazy"  
        bgImage={home3} 
        strength={500} bgImageStyle={{ objectFit: 'cover', width: '100%', height: '100%' }}
        className="parallax-desktop">
        <div className="parallax-container section-spacing">
          <div className="container-xl">
            <div className="row justify-content-start align-items-start">
              <div className="col-12 col-md-8 col-lg-4 text-start">
                <div className="parallax-content">
                  <h1 className="display-4" data-aos="fade-up" data-aos-delay="200">{t('parallax.title')}</h1>
                  <p className="lead" data-aos="fade-up" data-aos-delay="400">{t('parallax.description')}</p>
                  <div data-aos="fade-up" data-aos-delay="600">
                    <ScrollLink to="services" smooth={true} duration={500}>
                      <Button>{t('parallax.getStarted')}</Button>
                    </ScrollLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
      <Parallax 
        id='home' 
        loading="lazy"  
        bgImage={home4} 
        strength={500} 
        bgImageStyle={{ objectFit: 'cover', width: '100%', height: '100%' }}
        className="parallax-mobile">
        <div className="parallax-container  ">
          <div className="container-xl">
            <div className="row justify-content-start align-items-start">
              <div className="col-12 col-md-8 col-lg-4  text-start">
                <div className="parallax-content">
                  <h1 className="display-4" data-aos="fade-up" data-aos-delay="200">{t('parallax.title')}</h1>
                  <p className="lead" data-aos="fade-up" data-aos-delay="400">{t('parallax.description')}</p>
                  <div data-aos="fade-up" data-aos-delay="600">
                    <ScrollLink to="services" smooth={true} duration={500}>
                      <Button>{t('parallax.getStarted')}</Button>
                    </ScrollLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
      <div id="services-section">
        {/* Your Services Section Content */}
      </div>
    </>
  );
};

export default Home;
