import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initializeAOS } from '../animation/aos-init';
import 'aos/dist/aos.css';
import walkingCoachImage  from '../assets/img/about/walking-coach.png';
import walkingSessionImage  from '../assets/img/about/walking-session.png';
import Button from '../components/buttons';
import { Link as ScrollLink } from 'react-scroll';


const AboutPage = () => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);


  useEffect(() => {
    initializeAOS();
  }, []);

  const toggleReadMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <section className="about-section">
        <div className="container-xl">
          <div className="about-content">
            <div className="about-text" data-aos="fade-right">
              <h1 className="about-heading" >{t('about.heading')}</h1>
              <h2 className="about-title"data-aos="fade-up"data-aos-duration='1200'>{t('about.title')}</h2>
              <p className="about-subtitle"data-aos="fade-up"data-aos-duration='1400'>{t('about.subtitle')}</p>
              <p className="about-description" data-aos='fade-up'data-aos-duration='1600'>
                {showMore ? t('about.fullDescription') : t('about.shortDescription')}
              </p>
              <div className="about_btn" data-aos='fade-up'data-aos-duration='1600'>
                <Button onClick={toggleReadMore}>
                  {showMore ? t('about.readLess') : t('about.readMore')}
                </Button>
                <ScrollLink to="contact" smooth={true} duration={500}>
                  <Button variant="black-text">{t('about.contact')}</Button>
                </ScrollLink>
              </div>
            </div>
            <div className="about-images" data-aos="fade-left">
            <img src={walkingSessionImage} alt="Walking Coach" width="600" height="400" className="img-fluid main-image" loading="lazy" />
<img src={walkingCoachImage} alt="Walking Session" width="600" height="400" className="img-fluid secondary-image" loading="lazy" />

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutPage;

