import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Navbar from './App/components/Navbar';
import Home from './App/pages/Home';
import AboutPage from './App/pages/About';
import Services from './App/pages/Service';
import Events from './App/pages/Events';
import './App/css/main.css';
import CourseRegistration from './App/pages/Register';
import GallerySection from './App/pages/Gallery';
import ContactSection from './App/pages/Contact';
import Footer from './App/pages/footer';
import SanitizedContent from './App/components/SanitizedContent';
// import Admin from './App/components/Admin.jsx.bk';

function App() {
  const { t, i18n } = useTranslation();
  // const [isAdminPage, setIsAdminPage] = useState(false);

  // useEffect(() => {
  //   const handleHashChange = () => {
  //     setIsAdminPage(window.location.hash === '#admin');
  //   };

  //   window.addEventListener('hashchange', handleHashChange);
  //   handleHashChange();  // Trigger the function on initial load

  //   return () => {
  //     window.removeEventListener('hashchange', handleHashChange);
  //   };
  // }, []);

  // if (isAdminPage) {
  //   return (
  //     <div className='App'>
  //     <Admin />
  //     </div>
  //   );
  // }

  return (
    <div className='App'>
      <Helmet>
        <title>{t('seo.title')}</title>
        <meta name="description" content={t('seo.description')} />
        <meta name="keywords" content={t('seo.keywords')} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://walkalonesharetogether.nl/" />

        {/* Language alternates */}
        <link rel="alternate" hreflang="en" href="https://walkalonesharetogether.nl" />
        <link rel="alternate" hreflang="nl" href="https://walkalonesharetogether.nl" />

        {/* Open Graph tags for social media */}
        <meta property="og:title" content={t('seo.title')} />
        <meta property="og:description" content={t('seo.description')} />
        <meta property="og:url" content="https://walkalonesharetogether.nl/" />
        <meta property="og:type" content="website" />

        {/* Structured Data (JSON-LD) */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": t('seo.title'),
            "url": "https://walkalonesharetogether.nl/",
            "logo": "https://walkalonesharetogether.nl/#home",
            "description": t('seo.description'),
            "address": {
              "addressLocality": "Groningen",
              "addressCountry": "Netherlands"
            },
            "contactPoint": {
              'email':'walkalone.sharetogether@gmail.com',
              "contactType": "Customer Service"
            },
            "sameAs": [
              "https://www.instagram.com/walkalone_sharetogether/",
            ]
          })}
        </script>
      </Helmet>
      <Navbar />
      <div id="home">
        <Home />
      </div>
      <div id="about">
        <AboutPage />
      </div>
      <div id="services">
        <Services />
      </div>
      <div id="events">
        <Events />
      </div>
      <div id="register">
        <CourseRegistration />
      </div>
      <div id="gallery">
        <GallerySection />
      </div>
      <div id="contact">
        <ContactSection />
      </div>
      <SanitizedContent />
      <Footer />
    </div>
  );
}

export default App;
