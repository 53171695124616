import React from 'react';
import '../css/main.css';  
export const Button = ({ children, variant = 'button', ...props }) => {
  const buttonClass = variant === 'outline' ? 'button-outline' : variant === 'black-text' ? 'button-black-text' : 'button';

  return (
    <button className={buttonClass} {...props}>
      <span className="btn-title">{children}</span>
      <span className="arrow"></span>
    </button>
  );
};

export default Button;
