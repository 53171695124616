import React, { useState } from 'react';
import '../../App.css';
import { useTranslation } from 'react-i18next';
import { MdMiscellaneousServices } from "react-icons/md";
import { FaLongArrowAltRight } from "react-icons/fa";
import weekendWalksImage from '../assets/img/service/weekend-walks.jpg';
import seasonalWalksImage from '../assets/img/service/seasonal-walks.jpg';
import personalJourneyImage from '../assets/img/service/personal-journey.jpg';
import photoWalkImage from '../assets/img/service/photo-walk.jpg';
import coachingSessionImage from '../assets/img/service/coaching-session.jpg';
import Button from '../components/buttons';
import { Link as ScrollLink } from 'react-scroll';

export const Services = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('tab-1');
  const [expandedService, setExpandedService] = useState(null);

  const services = [
    {
      id: 'tab-1',
      title: t('services.servicesList.wandelweekenden'),
      description: t('services.wandelweekenden.description'),
      // details: t('services.wandelweekenden.details'),
      dates: t('services.wandelweekenden.dates', { returnObjects: true }),
      expectations: t('services.wandelweekenden.expectations', { returnObjects: true }),
      icon: 'flaticon-walk',
      hoverIcon: 'flaticon-walk-hover',
      image: weekendWalksImage,
      imageAlt: "Weekend Walks",
      serviceLink: 'wandelweekenden-service.html',
      appointmentLink: 'appointment.html'
    },
    {
      id: 'tab-2',
      title: t('services.servicesList.seizoenswandelingen'),
      description: t('services.seizoenswandelingen.description'),
      dates: t('services.seizoenswandelingen.dates', { returnObjects: true }),
      note: t('services.seizoenswandelingen.note'),
      icon: 'flaticon-season',
      hoverIcon: 'flaticon-season-hover',
      image: seasonalWalksImage,
      imageAlt: "Seasonal Walks",
      serviceLink: 'seizoenswandelingen-service.html',
      appointmentLink: 'appointment.html'
    },
    {
      id: 'tab-3',
      title: t('services.servicesList.persoonlijkeVerdiepingsreis'),
      description: t('services.persoonlijkeVerdiepingsreis.description'),
      details: t('services.persoonlijkeVerdiepingsreis.details'),
      icon: 'flaticon-personal',
      hoverIcon: 'flaticon-personal-hover',
      image: personalJourneyImage,
      imageAlt: "Personal Journey",
      serviceLink: 'persoonlijke-verdiepingsreis-service.html',
      appointmentLink: 'appointment.html'
    },
    {
      id: 'tab-4',
      title: t('services.servicesList.fotowandeling'),
      description: t('services.fotowandeling.description'),
      icon: 'flaticon-photo',
      hoverIcon: 'flaticon-photo-hover',
      image: photoWalkImage,
      imageAlt: "Photo Walk",
      serviceLink: 'fotowandeling-service.html',
      appointmentLink: 'appointment.html'
    },
    {
      id: 'tab-5',
      title: t('services.servicesList.coaching'),
      description: t('services.coaching.description'),
      shortDescription: t('services.coaching.shortDescription'),
      offerings: t('services.coaching.offerings', { returnObjects: true }),
      approach: t('services.coaching.approach'),
      icon: 'flaticon-coaching',
      hoverIcon: 'flaticon-coaching-hover',
      image: coachingSessionImage,
      imageAlt: "Coaching Session",
      serviceLink: 'coaching-service.html',
      appointmentLink: 'appointment.html'
    }
  ];

  const handleMoreAboutService = (id) => {
    setExpandedService(expandedService === id ? null : id);
  };

  return (
    <>
      <div className="section-spacing"></div>
      <section id="services"className="Service_section section-spacing" data-aos="fade-right">
        <div className="tabs-box service-tabs">
          <div className="upper-box">
            <div className="pattern-layer"></div>
            <div className="container">
              <div className="sec-title">
                <div className="title-icon">
                  <span className="icon">
                    <MdMiscellaneousServices className='Leaf_Icon' />
                  </span>
                </div>
                <div  className="subtitle"    data-aos-delay="200">{t('services.subtitle')}</div>
                <h2    data-aos-delay="400">{t('services.sectionTitle')}</h2>
              </div>
              <div className="buttons">
                <ul className="tab-buttons row clearfix">
                  {services.map(service => (
                    <li
                      key={service.id}
                      className={`tab-btn col ${activeTab === service.id ? 'active-btn' : ''}`}
                      data-tab={`#${service.id}`}
                      onClick={() => setActiveTab(service.id)}
                    >
                      <span className="hvr-seeds"></span>
                      <div className="icon-box">
                        <span className={`icon ${service.icon}`}></span>
                        <span className={`icon hvr-icon ${service.hoverIcon}`}></span>
                      </div>
                      <div className="btn-title">{service.title}</div>
                      <FaLongArrowAltRight className="arrow flaticon-right-1"></FaLongArrowAltRight>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="tabs-content" data-aos="fade-right">
            {services.map(service => (
              <div
                className={`tab ${activeTab === service.id ? 'active-tab' : ''}`}
                id={service.id}
                key={service.id}
                style={{ display: activeTab === service.id ? 'block' : 'none' }}
              >
                <div className="row outer-container clearfix">
                  <div className="image-column col-lg-6 col-md-12 col-sm-12 fixed-height">
                    <div className="image-layer  "loading="lazy" alt={service.imageAlt} style={{ backgroundImage: `url(${service.image})` }}></div>
                    <div className="inner clearfix">
                      <div className="content">
                        <div className="inner-box">
                          <div className="icon-box">
                            <span className="flaticon-leaves"></span>
                          </div>
                          <h5>{t('services.startProject')}</h5>
                          <div className="text">{t('services.appointmentText')}</div>
                          <div className="link-box clearfix">
                          <ScrollLink to='register'smooth={true} duration={500}> 
                            <Button variant="outline">{t('services.appointmentBtn')}</Button>
                            </ScrollLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-column col-lg-6 col-md-12 col-sm-12">
                    <div className="big-icon">
                      <span className={service.hoverIcon}></span>
                    </div>
                    <div className="inner">
                      <div className="content">
                        <div className="s-title">
                          <div className="icon">
                            <span className={service.hoverIcon}></span>
                          </div>
                          <div className="subtitle">{t('services.knowAbout')}</div>
                          <h4>{service.title}</h4>
                        </div>
                        <div className="text">{service.shortDescription || service.description}</div>
                        {expandedService === service.id && (
                          <React.Fragment>
                            {service.details && <div className="text">{service.details}</div>}
                            {service.dates && (
                              <div className="text">
                                {/* <strong>Dates:</strong> */}
                                {/* <ul>
                                  {service.dates.map((date, index) => (
                                    <li key={index}>{date}</li>
                                  ))}
                                </ul> */}
                              </div>
                            )}
                            {service.expectations && (
                              <div className="text">
                                <strong>verwachtingen:</strong>
                                <ul>
                                  {service.expectations.map((expectation, index) => (
                                    <li key={index}>{expectation}</li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            {service.offerings && (
                              <div className="text">
                                <strong>aanbiedingen</strong>
                                <ul>
                                  {service.offerings.map((offering, index) => (
                                    <li key={index}>{offering}</li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            {service.approach && <div className="text">{service.approach}</div>}
                            {service.note && <div className="text">{service.note}</div>}
                          </React.Fragment>
                        )}
                        <Button
                          variant='button-outline'
                          onClick={() => handleMoreAboutService(service.id)}
                        
                        >
                          {expandedService === service.id ? t('services.moreAboutServiceLess') : t('services.moreAboutService')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
