import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';
import registrationImage from '../assets/img/register/registration.jpg';
import decorativeIcon from '../assets/img/register/decorative-icon.png';
import Button from '../components/buttons';
import Select from 'react-select';  

export const CourseRegistration = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
    AOS.refresh();
  }, [step]);

  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    course: '',
    day: ''
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, 'First name must be at least 2 characters')
      .max(50, 'First name must be at most 50 characters')
      .matches(/^[a-zA-Z\s]*$/, 'First name can only contain letters')
      .required('First name is required'),
    lastName: Yup.string()
      .min(2, 'Last name must be at least 2 characters')
      .max(50, 'Last name must be at most 50 characters')
      .matches(/^[a-zA-Z\s]*$/, 'Last name can only contain letters')
      .required('Last name is required'),
    phone: Yup.string()
      .matches(/^\d+$/, 'Phone number must contain only numbers')
      .min(10, 'Phone number must be at least 10 digits')
      .max(15, 'Phone number must be at most 15 digits')
      .required('Phone number is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email address is required'),
    course: Yup.string().required('Course selection is required'),
    day: Yup.date().required('Date selection is required')
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const templateParams = {
      to_name: 'Anna',  
      ...values
    };

    emailjs.send('service_8v0m6o9', 'template_zl2drv8', templateParams, 'onEmf_yGBQ0iE3M9w')
    .then((result) => {
      console.log(result.text);
      setSubmitted(true);
      setTimeout(() => {
        window.location.href = '/';  
      }, 3000);
    }, (error) => {
      console.log(error.text);
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const courseOptions = [
    { value: 'autumnHikingRetreat', label: t('courseRegistration.events.autumnHikingRetreat') },
    { value: 'autumnPhotographyWalk', label: t('courseRegistration.events.autumnPhotographyWalk') },
    { value: 'winterWonderlandWalk', label: t('courseRegistration.events.winterWonderlandWalk') },
    { value: 'springAwakeningWalk', label: t('courseRegistration.events.springAwakeningWalk') },
    { value: 'summerSolsticeWalk', label: t('courseRegistration.events.summerSolsticeWalk') },
 
  ];

  return (
    <>
      <div className="section-spacing"></div>
      <section className="course-reg-section">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-12 text-center">
              <div className="course-title" data-aos="fade-down">
                <div className="title-decor">
                  <div className="line"></div>
                  <img src={decorativeIcon} loading="lazy" alt="Decorative Icon Left" className="decor-icon" />
                </div>
                <div className="title-content">
                  <h1 className="reg-heading mb-2">{t('courseRegistration.title')}</h1>
                  <p className="reg-subtitle mb-5">{t('courseRegistration.subtitle')}</p>
                </div>
                <div className="title-decor">
                  <img src={decorativeIcon} loading="lazy" alt="Decorative Icon Right" className="decor-icon" />
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gap-row">
            <div className="col-md-6 d-flex align-items-stretch" data-aos="fade-right">
              <img src={registrationImage} loading="lazy" alt="Course Registration" className="img-fluid reg-img-fluid" />
            </div>
            <div className="col-md-6 d-flex align-items-stretch" data-aos="fade-left">
              <div className="form-container">
                {!submitted ? (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting, values, setFieldValue }) => (
                      <Form id="registrationForm">
                        {step === 1 && (
                          <div className="step-content" data-aos="fade-up">
                            <h2 className="reg-main-title">{t('courseRegistration.steps.step1')}</h2>
                            <div className="reg-form-group">
                              <Field
                                type="text"
                                id="firstName"
                                name="firstName"
                                className="reg-form-control"
                                placeholder={t('courseRegistration.labels.firstName')}
                              />
                              <ErrorMessage name="firstName" component="div" className="error-message" />
                            </div>
                            <div className="reg-form-group">
                              <Field
                                type="text"
                                id="lastName"
                                name="lastName"
                                className="reg-form-control"
                                placeholder={t('courseRegistration.labels.lastName')}
                              />
                              <ErrorMessage name="lastName" component="div" className="error-message" />
                            </div>
                            <div className="reg-form-group">
                              <Field
                                type="tel"
                                id="phone"
                                name="phone"
                                className="reg-form-control"
                                placeholder={t('courseRegistration.labels.phone')}
                              />
                              <ErrorMessage name="phone" component="div" className="error-message" />
                            </div>
                            <div className="reg-form-group">
                              <Field
                                type="email"
                                id="email"
                                name="email"
                                className="reg-form-control"
                                placeholder={t('courseRegistration.labels.email')}
                              />
                              <ErrorMessage name="email" component="div" className="error-message" />
                            </div>
                            <div className="button-group">
                              <Button variant='button-outline' type="button" onClick={() => setStep(2)}>
                                {t('courseRegistration.buttons.next')}
                              </Button>
                            </div>
                          </div>
                        )}
                        {step === 2 && (
                          <div className="step-content" data-aos="fade-up">
                            <h2 className="reg-main-title">{t('courseRegistration.steps.step2')}</h2>
                            <div className="reg-form-group">
                              <label htmlFor="course" className="reg-label">{t('courseRegistration.labels.course')}</label>
                              <Select
                                id="course"
                                name="course"
                                options={courseOptions}
                                className="reg-form-control"
                                onChange={(option) => setFieldValue('course', option.value)}
                              />
                              <ErrorMessage name="course" component="div" className="error-message" />
                            </div>
                            <div className="reg-form-group">
                              <label htmlFor="day" className="reg-label">{t('courseRegistration.labels.day')}</label>
                              <Field
                                type="date"
                                id="day"
                                name="day"
                                className="reg-form-control"
                              />
                              <ErrorMessage name="day" component="div" className="error-message" />
                            </div>
                            <div className="button-group">
                              <Button variant='black-text' type="button" onClick={() => setStep(1)}>
                                {t('courseRegistration.buttons.previous')}
                              </Button>
                              <Button type="button" onClick={() => setStep(3)}>
                                {t('courseRegistration.buttons.next')}
                              </Button>
                            </div>
                          </div>
                        )}
                        {step === 3 && (
                          <div className="step-content" data-aos="fade-up">
                            <h2 className="reg-main-title">{t('courseRegistration.steps.step3')}</h2>
                            <ul className="reg-review-list">
                              <li><strong>{t('courseRegistration.review.firstName')}:</strong> {values.firstName}</li>
                              <li><strong>{t('courseRegistration.review.lastName')}:</strong> {values.lastName}</li>
                              <li><strong>{t('courseRegistration.review.phone')}:</strong> {values.phone}</li>
                              <li><strong>{t('courseRegistration.review.email')}:</strong> {values.email}</li>
                              <li><strong>{t('courseRegistration.review.course')}:</strong> {values.course}</li>
                              <li><strong>{t('courseRegistration.review.day')}:</strong> {values.day}</li>
                            </ul>
                            <div className="button-group">
                              <Button variant='black-text' type="button" onClick={() => setStep(2)}>
                                {t('courseRegistration.buttons.previous')}
                              </Button>
                              <Button type="submit" disabled={isSubmitting}>
                                {t('courseRegistration.buttons.submit')}
                              </Button>
                            </div>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div className="success-message" data-aos="fade-up">
                    <h2>{t('courseRegistration.success.title')}</h2>
                    <p>{t('courseRegistration.success.message')}</p>
                    <p>{t('courseRegistration.success.redirectMessage')}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseRegistration;
