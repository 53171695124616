import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ScrollLink, scroller } from 'react-scroll';
import logo from '../assets/img/logo.png';
import { initializeAOS } from '../animation/aos-init';
import 'aos/dist/aos.css';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    initializeAOS();

    const onScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavClick = (to) => {
    window.history.pushState(null, null, `#${to}`);
    scroller.scrollTo(to, {
      duration: 500,
      smooth: true,
    });
    setMenuOpen(false);
  };

  return (
    <nav className={`navbar fixed-top ${scrolled ? 'transparent' : ''}`} data-aos="fade-down">
      <div className="container">
        <a className="navbar-brand" href="#home" data-aos="zoom-in">
          <img 
            src={logo} 
            type="image/webp"
            alt="Logo" 
            className="logo" 
            loading="lazy" 
            width="200"  
            height="200"  
          />
        </a>
        <div
          className={`hamburger-menu ${menuOpen ? 'open' : ''}`}
          onClick={toggleMenu}
        >
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <div className={`nav-menu ${menuOpen ? 'open' : ''}`}>
          <ul className="nav-list">
            <li className="nav-item" data-aos="fade-up" data-aos-delay="100">
              <span
                className="nav-link"
                onClick={() => handleNavClick('home')}
              >
                {t('nav.home')}
              </span>
            </li>
            <li className="nav-item" data-aos="fade-up" data-aos-delay="200">
              <span
                className="nav-link"
                onClick={() => handleNavClick('about')}
              >
                {t('nav.about')}
              </span>
            </li>
            <li className="nav-item" data-aos="fade-up" data-aos-delay="300">
              <span
                className="nav-link"
                onClick={() => handleNavClick('services')}
              >
                {t('nav.services')}
              </span>
            </li>
            <li className="nav-item" data-aos="fade-up" data-aos-delay="400">
              <span
                className="nav-link"
                onClick={() => handleNavClick('events')}
              >
                {t('nav.events')}
              </span>
            </li>
            <li className="nav-item" data-aos="fade-up" data-aos-delay="500">
              <span
                className="nav-link"
                onClick={() => handleNavClick('register')}
              >
                {t('nav.register')}
              </span>
            </li>
            <li className="nav-item" data-aos="fade-up" data-aos-delay="600">
              <span
                className="nav-link"
                onClick={() => handleNavClick('gallery')}
              >
                {t('nav.gallery')}
              </span>
            </li>
            <li className="nav-item" data-aos="fade-up" data-aos-delay="700">
              <span
                className="nav-link"
                onClick={() => handleNavClick('contact')}
              >
                {t('nav.contact')}
              </span>
            </li>
          </ul>
        </div>
        <div id="language-switcher" className="language-switcher">
          <button
            className={`language-button ${i18n.language === 'en' ? 'active' : ''}`}
            onClick={() => changeLanguage('en')}
          >
            <span role="img" aria-label="English">🇺🇸</span>
          </button>
          <button
            className={`language-button ${i18n.language === 'nl' ? 'active' : ''}`}
            onClick={() => changeLanguage('nl')}
          >
            <span role="img" aria-label="Dutch">🇳🇱</span>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
