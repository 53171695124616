import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false,  
    });
  }, []);

  return (
    <>
      <div className="section-spacing"></div>
      <footer className="footer text-light py-5">
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-col mb-4" data-aos="fade-up">
              <h4>{t('footer.quickLinks')}</h4>
              <ul className="list-unstyled">
                <li>
                  <Link to="home" smooth={true} duration={500} className="text-light">
                    {t('nav.home')}
                  </Link>
                </li>
                <li>
                  <Link to="about" smooth={true} duration={500} className="text-light">
                    {t('nav.about')}
                  </Link>
                </li>
                <li>
                  <Link to="services" smooth={true} duration={500} className="text-light">
                    {t('nav.services')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-col mb-4" data-aos="fade-up">
              <h4>{t('footer.quickLinks')}</h4>
              <ul className="list-unstyled">
                <li>
                  <Link to="events" smooth={true} duration={500} className="text-light">
                    {t('nav.events')}
                  </Link>
                </li>
                <li>
                  <Link to="register" smooth={true} duration={500} className="text-light">
                    {t('nav.register')}
                  </Link>
                </li>
                <li>
                  <Link to="gallery" smooth={true} duration={500} className="text-light">
                    {t('nav.gallery')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-col mb-4" data-aos="fade-up">
              <h4>{t('footer.followUs')}</h4>
              <div className="social-icons">
                <a href="https://www.instagram.com/walkalone_sharetogether/" className="text-light me-3" target="_blank">
                  <FaInstagram />
                </a>
                
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footer-col mb-4" data-aos="fade-up">
              <h4>{t('footer.contactInfo')}</h4>
              <p className="text-light">Provincie Groningen</p>
              <p className="text-light">Email:walkalone.sharetogether@gmail.com</p>
            </div>
          </div>
          <div className="footer-copyright mt-4">
            <p className="text-center">
              <hr />
              <a className="footer-copyright-link" href="#" target="_self">
                ©2024. | Designed By:  Rahma Touihri  & Sasha Hu | All rights reserved.
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
