import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import nl from './nl.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      nl: {
        translation: nl
      }
    },
    lng: 'nl', // default language
    fallbackLng: 'nl',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
