import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

const API_ENDPOINT = '/.netlify/functions/fetchEvents';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const { t } = useTranslation();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(API_ENDPOINT);
        setEvents(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching events');
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center vh-100">Loading...</div>;
  }

  if (error) {
    return <div className="d-flex justify-content-center align-items-center vh-100">Error: {error}</div>;
  }

  return (
    <>
      <div className="section-spacing"></div>
      <header className="header d-flex flex-column justify-content-center align-items-center" data-aos="fade-right">
        <div className="header-container">
          <h1 className="header-title">{t('events.sectionTitle')}</h1>
        </div>
        <p className="header-subtitle">{t('events.subtitle')}</p>
      </header>

      <section className="events-section" data-aos="fade-right">
        <div className="container-xl p-0">
          <div className="row no-gutters">
            <div className="col-lg-4 calendar-container">
              <div className="calendar">
                <div className="calendar-header">
                  <h2>{currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}</h2>
                  <div className="calendar-controls">
                    <button onClick={handlePrevMonth}>&lt;</button>
                    <button onClick={handleNextMonth}>&gt;</button>
                  </div>
                </div>
                <div className="calendar-body" data-aos="fade-right">
                  <div className="day-names">
                    <div>{t('events.calendar.sun')}</div>
                    <div>{t('events.calendar.mon')}</div>
                    <div>{t('events.calendar.tue')}</div>
                    <div>{t('events.calendar.wed')}</div>
                    <div>{t('events.calendar.thu')}</div>
                    <div>{t('events.calendar.fri')}</div>
                    <div>{t('events.calendar.sat')}</div>
                  </div>
                  <div className="dates">
                    {Array.from({ length: firstDayOfMonth }).map((_, i) => (
                      <div key={`empty-${i}`} className="empty"></div>
                    ))}
                    {Array.from({ length: daysInMonth(currentDate.getMonth(), currentDate.getFullYear()) }).map((_, i) => {
                      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i + 1);
                      const isToday = date.toDateString() === new Date().toDateString();
                      const event = events.find(event => new Date(event.Date).toDateString() === date.toDateString());

                      return (
                        <div key={i} className={`date calendar-date ${isToday ? 'today' : ''}`}>
                          {i + 1}
                          {event && (
                            <div className="event-details">
                              <h3>{event.Name}</h3>
                              <p>{event.Type}</p>
                              <p>{event.Time}</p>
                              <p>{event.Location}</p>
                              <p>{event.Duration}</p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 event-list-container d-flex flex-column justify-content-center align-items-start">
              <div className="event-header">
                <h2 className="text_local">{t('events.localEvents')}</h2>
                <div className="line_event_calender"></div>
              </div>
              <div className="event-tabs d-flex justify-content-start mb-4">
                <button className="active">{t('events.tabs.upcoming')}</button>
              </div>
              <div className="event-list">
                <div className="events">
                  {loading ? (
                    <div>Loading...</div>
                  ) : (
                    events.map((event, index) => (
                      <div key={index} className={`event-item ${new Date(event.Date) < new Date() ? 'passed' : ''}`}>
                        <div className="time">
                          {event.Time}
                          <div className="type">{event.Type}</div>
                        </div>
                        <div className="details">
                          <h3>{event.Name}</h3>
                          <div className="location">{event.Location}</div>
                        </div>
                        <div className="date">{event.Duration}</div>
                      </div>
                    ))
                  )}
                  {!loading && events.length === 0 && (
                    <p>No events available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Events;
