import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

const GALLERY_API_ENDPOINT = '/.netlify/functions/fetchGalleryImages';

const GallerySection = () => {
  const { t } = useTranslation();
  const [galleryImages, setGalleryImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const response = await axios.get(GALLERY_API_ENDPOINT);
        const fetchedGalleryImages = response.data.map(image => ({
          src: image['Image URL'],
          alt: image['Alt Text'],
          animation: image['Animation'],
        }));
        setGalleryImages(fetchedGalleryImages.slice(0, 8)); // Limit to 8 images
      } catch (err) {
        setError('Error fetching gallery images');
      } finally {
        setLoading(false);
      }
    };

    fetchGalleryImages();
    AOS.init({ duration: 1200, once: true });
  }, []);

  useEffect(() => {
    AOS.refresh(); // Refresh AOS animations on gallery update
  }, [galleryImages]);

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center vh-100">Loading...</div>;
  }

  if (error) {
    return <div className="d-flex justify-content-center align-items-center vh-100">Error: {error}</div>;
  }

  return (
    <>
      <div className="section-spacing"></div>
      <div id="Gallery" className="gallery-container">
        <div className="container-xl">
          <div className="gallery-header" data-aos="fade-down">
            <h1>{t('gallery.title')}</h1>
            <p className="page-description">{t('gallery.description')}</p>
          </div>
          <div className="gallery-grid">
            {galleryImages.map((image, index) => (
              <div key={index} className="gallery-item" data-aos={image.animation}>
                <img src={image.src} alt={image.alt} className="img-fluid rounded" />
              </div>
            ))}
          </div>
        </div>
        <div className="space"></div>
      </div>
    </>
  );
};

export default GallerySection;
