import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import Button from "../components/buttons";
import emailjs from 'emailjs-com';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
});

export function ContactSection() {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true, 
    });
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    const templateParams = {
      ...values
    };

    emailjs.send('service_y80vr8c', 'template_q4k2uth', templateParams, 'onEmf_yGBQ0iE3M9w')
      .then((result) => {
        console.log(result.text);
        setSubmitted(true);
        setTimeout(() => {
          window.location.href = '/';  
        }, 3000);  
      }, (error) => {
        console.log(error.text);
      }).finally(() => {
        setSubmitting(false); 
      });
  };

  return (
    <div className="contact-section">
      <div className="container-xl mt-5" data-aos="fade-up">
        <div className="contact-wrap">
          <div className="row">
            <div className="col-lg-4 sm-padding" data-aos="fade-right">
              <div className="contact-content bg-light p-4 rounded">
                <h2 className="h3 mb-3">{t('contact.title')}</h2>
                <p className="para">{t('contact.description')}</p>
                <div className="contact-info mt-4">
                  <div className="contact-info-item mb-3">
                    <div className="contact-info-icon">
                      <FaEnvelope />
                    </div>
                    <div className="contact-info-content">
                      <span className="contact-info-label">{t('contact.email.label')}</span>
                      <span className="contact-info-dec">{t('contact.email.address')}</span>
                    </div>
                  </div>
                  <div className="contact-info-item">
                    <div className="contact-info-icon">
                      <FaMapMarkerAlt />
                    </div>
                    <div className="contact-info-content">
                      <span className="contact-info-label">{t('contact.location.label')}</span>
                      <span className="contact-info-dec">{t('contact.location.address')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 sm-padding" data-aos="fade-left">
              <div className="bg-light p-4 rounded">
                {!submitted ? (
                  <Formik
                    initialValues={{ name: '', email: '', phone: '', subject: '', message: '' }}
                    validationSchema={ContactSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form className="row g-3">
                        <div className="col-md-6">
                          <Field
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder={t('contact.form.name')}
                          />
                          <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-6">
                          <Field
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder={t('contact.form.email')}
                          />
                          <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-6">
                          <Field
                            type="text"
                            name="phone"
                            className="form-control"
                            placeholder={t('contact.form.phone')}
                          />
                          <ErrorMessage name="phone" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-6">
                          <Field
                            type="text"
                            name="subject"
                            className="form-control"
                            placeholder={t('contact.form.subject')}
                          />
                          <ErrorMessage name="subject" component="div" className="text-danger" />
                        </div>
                        <div className="col-12">
                          <Field
                            as="textarea"
                            name="message"
                            className="form-control"
                            placeholder={t('contact.form.message')}
                            rows="5"
                          />
                          <ErrorMessage name="message" component="div" className="text-danger" />
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                          <Button variant='button-outline' type="submit" disabled={isSubmitting}>
                            {t('contact.form.submit')}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div className="success-message" data-aos="fade-up">
                    <h2>{t('contact.success.title')}</h2>
                    <p>{t('contact.success.message')}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
